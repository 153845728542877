<template>
  <div class="myAssTag">
    <!-- 我的考核指标 -->
    <van-nav-bar
      :left-text="$t('myAssTaget.tagTxt1')"
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
      @click-right="showPicker = true"
    >
      <template #right>
        {{ khtime.khtimename }} <van-icon name="arrow-down" size="18" />
        <div v-show="activeName == 'KPITag'">
          ({{ lastscore }}{{ $t('jxMyAssTargetDetails.txt20') }})
        </div>
      </template>
    </van-nav-bar>
    <div class="content">
      <van-tabs
        v-model="activeName"
        color="#2B8DF0"
        title-active-color="#2B8DF0"
        @change="changeActive"
      >
        <van-tab
          v-if="$t('myAssTaget.tagTxt2') && khtime.is360"
          :title="`${$t('myAssTaget.tagTxt2')}${tit360}`"
          name="360Tag"
        ></van-tab>
        <van-tab
          v-if="$t('myAssTaget.tagTxt3') && khtime.iskpi"
          :title="`${$t('myAssTaget.tagTxt3')}${titKPI}`"
          name="KPITag"
        ></van-tab>
        <van-tab
          v-if="$t('myAssTaget.tagTxt4') && khtime.isgs"
          :title="`${$t('myAssTaget.tagTxt4')}${titGS}`"
          name="GSTag"
        ></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isLoadingAwait" @refresh="onRefreshAwait">
        <van-list
          v-model="loadingAwait"
          :finished="finishedAwait"
          finished-text="没有更多了"
          @load="onLoadAwait"
        >
          <div
            style=" text-align: center;color: red;font-size: 12px;"
            v-if="
              (activeName == '360Tag' && Base360.noreqcheckreason) ||
                (activeName == 'KPITag' && BaseKPI.noreqcheckreason)
            "
          >
            {{
              activeName == '360Tag'
                ? Base360.noreqcheckreason
                : BaseKPI.noreqcheckreason
            }}
          </div>
          <div
            class="zb_group"
            v-for="(item, index) in assessList"
            :key="index"
            @click="goToDetails(item)"
          >
            <div v-if="activeName == 'GSTag'">
              <van-cell
                class="ass_cell"
                size="large"
                :title="item.taskname"
                :label="`${item.empname}-${item.deptname}`"
              >
                <template slot="label">
                  {{ item.empname }}-{{ item.deptname }}<br />
                  周期：{{ item.khtimename }}
                </template>
                <template>
                  <div>
                    权重：<span class="custom-score">{{ item.taskper }}</span>
                  </div>
                  <div>{{ item.reqdate }}</div>
                  <div class="task_ischeck">
                    <div v-if="item.task_is_ischeck" style="color:red;">
                      已确认！
                    </div>
                    <van-button
                      v-else
                      type="warning"
                      size="mini"
                      @click.stop="confirm(item)"
                      >我要确认</van-button
                    >
                  </div>
                </template>
              </van-cell>
            </div>
            <van-cell-group v-else>
              <van-cell
                class="top_cell"
                center
                :title="
                  activeName == '360Tag' ? item.indicatorname : item.itemname
                "
                :value="activeName == '360Tag' ? item.typename : item.itemtype"
              >
                <template #right-icon>
                  <van-icon
                    :name="deleteAss"
                    style="margin-left: 10px"
                    @click.stop="deleteTag(item)"
                  />
                </template>
              </van-cell>
              <van-cell class="btm_cell" center>
                <template #title>
                  <span v-if="activeName == '360Tag'" class="custom-title">{{
                    item.indicatordesp
                  }}</span>
                  <span v-else class="custom-title">{{ item.itemdesc }}</span>
                </template>
                <template #label>
                  <span v-if="activeName == '360Tag'" class="custom-score">{{
                    item.score
                  }}</span>
                  <span v-else class="custom-score">{{ item.tgvalue }}</span>
                  {{ $t('jxApprovalTagApply.atTxt1') }}
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="footer">
      <van-button
        type="warning"
        size="small"
        v-if="showFoot"
        @click="addItem"
        >{{ $t('jxMyAssTargetDetails.txt18') }}</van-button
      >
      <van-button
        type="info"
        size="small"
        v-if="showFoot"
        @click="subbitItem"
        >{{ $t('jxMyAssTargetDetails.txt19') }}</van-button
      >
      <van-button plain type="info" size="small" @click="copy"
        >复制指标</van-button
      >
      <van-button
        v-if="
          activeName == 'GSTag' ||
            (activeName == '360Tag' && Base360.checkflag == 2) ||
            (activeName == 'KPITag' && BaseKPI.checkflag == 2)
        "
        type="primary"
        size="small"
        @click="cb"
        >我要催办</van-button
      >
      <!-- <van-button type="info"
                  size="small"
                  @click="goExamine">发起指标申请</van-button> -->
    </div>
    <van-popup
      v-model="showPicker"
      :close-on-click-overlay="false"
      :close-on-popstate="false"
      round
      position="bottom"
    >
      <van-picker
        :title="'请选择考核周期'"
        show-toolbar
        :columns="
          khTimeList.map(e => {
            return e.khtimename
          })
        "
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import {
  // getMy360KhList,
  // getMyKpiKhList,
  // getMyGsTaskKhTime,
  getKhTimeList,
  getMy360IndicatorList_ForKhTime,
  getMyKpiIndicatorList_ForKhTime,
  getMyGsTaskList,
  deleteMy360Indicator,
  deleteMyKpiIndicator,
  copyLastTimeIndicator,
  urgeMyIndicator,
  getMyKpiTotalScore,
  postMy360PlanIndicator,
  postMyKpiPlanIndicator,
  checkMyTask
} from '@api/wxjx.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
      activeName: '360Tag',
      showPicker: false,
      khTimeList: [],
      khtime: { autid: 0 },
      tit360: '',
      titKPI: '',
      titGS: '',
      deleteAss: require('@/assets/img/deleteAss.png'),
      Base360: { noreqcheckreason: null, checkflag: 0 },
      BaseKPI: { noreqcheckreason: null, checkflag: 0 },
      lastscore: 0,
      readonly: '0'
    }
  },
  computed: {
    showFoot() {
      if (
        (this.activeName == '360Tag' &&
          (this.Base360.checkflag == 1 || this.Base360.checkflag == 4)) ||
        (this.activeName == 'KPITag' &&
          (this.BaseKPI.checkflag == 1 || this.BaseKPI.checkflag == 4))
      ) {
        return true
      } else {
        return false
      }
    }
  },
  created() {},
  //beforeRouteLeave
  beforeRouteEnter(to, from, next) {
    let is360 = true
    if (
      from.name == 'jxApprovalTagDetails' ||
      from.name == 'jxMyAssTargetDetails'
    ) {
      is360 = from.query.is360
    }
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。

      if (from.name == 'myGsDetails') {
        vm.activeName = 'GSTag'
      } else {
        if (is360 == true || is360 == 'true') {
          vm.activeName = '360Tag'
        } else {
          vm.activeName = 'KPITag'
        }
      }
    })
  },
  methods: {
    onConfirm(value, index) {
      this.setKhtime(this.khTimeList[index])
    },
    setKhtime(n) {
      this.khtime = n
      if (
        (this.activeName == 'GSTag' && n.isgs) ||
        (this.activeName == 'KPITag' && n.iskpi) ||
        (this.activeName == '360Tag' && n.is360)
      ) {
      } else {
        this.activeName = n.is360 ? '360Tag' : n.iskpi ? 'KPITag' : 'GSTag'
      }
      this.showPicker = false
      this.onLoadAwait()
      Toast(this.$t('module.refreshSuccessfully'))
    },
    _getKhTimeList() {
      getKhTimeList({
        username: this.userInfo.username,
        flag: 0
      }).then(async res => {
        this.khTimeList = res.data
        if (res.data.length > 0) {
          if (this.$route.query.khtimeid) {
            // this.activeName = 'GSTag'
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index]
              if (element.autoid == this.$route.query.khtimeid) {
                this.setKhtime(element)
              }
            }
          } else {
            this.setKhtime(res.data[0])
          }
        }
        this.onLoadAwait()
        Toast(this.$t('module.refreshSuccessfully'))
      })
    },
    onClickLeft() {
      this.$router.push('/home')
    },

    // 获取“我的360指标”记录列表
    getMy360KhListData() {
      let _this = this
      this.assessList = []
      getMy360IndicatorList_ForKhTime({
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        this.Base360 =
          res.baseinfo.length > 0
            ? res.baseinfo[0]
            : {
                autoid: 0,
                checkflag: 0,
                noreqcheckreason: null
              }
        //checkflag 含义如下：--1：待提交，2：审核中，3：同意，4：不同意
        this.tit360 =
          res.baseinfo.length > 0
            ? res.baseinfo[0].checkflag == 1
              ? '(待提交)'
              : res.baseinfo[0].checkflag == 2
              ? '(审核中)'
              : res.baseinfo[0].checkflag == 3
              ? '(同意)'
              : res.baseinfo[0].checkflag == 4
              ? '(不同意)'
              : ''
            : ''
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    // 获取“我的KPI指标”记录列表
    getMyKpiKhListData() {
      let _this = this
      this.assessList = []
      getMyKpiIndicatorList_ForKhTime({
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        //checkflag 含义如下：--1：待提交，2：审核中，3：同意，4：不同意
        this.BaseKPI =
          res.baseinfo.length > 0
            ? res.baseinfo[0]
            : {
                autoid: 0,
                checkflag: 0,
                is_jjp: false,
                is_zjp: false,
                is_zp: false,
                khtimename: '',
                lastscore: 0,
                noreqcheckreason: null,
                planname: '',
                req_indicator_select: false,
                usejj_forid: false,
                usezj_forid: false
              }
        this.readonly =
          res.baseinfo.length > 0
            ? res.baseinfo[0].checkflag == 2 || res.baseinfo[0].checkflag == 3
              ? '1'
              : '0'
            : '0'
        this.titKPI =
          res.baseinfo.length > 0
            ? res.baseinfo[0].checkflag == 1
              ? '(待提交)'
              : res.baseinfo[0].checkflag == 2
              ? '(审核中)'
              : res.baseinfo[0].checkflag == 3
              ? '(同意)'
              : res.baseinfo[0].checkflag == 4
              ? '(不同意)'
              : ''
            : ''
        // 刷新总分值
        getMyKpiTotalScore({
          empid: this.userInfo.empid,
          mautoid: res.baseinfo[0].autoid
        }).then(resdata => {
          this.lastscore = resdata.data[0].totalscore
        })
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    // 获取“我的GS指标”记录列表
    getMyGsKhListData() {
      let _this = this
      this.assessList = []
      getMyGsTaskList({
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        if (res.data.length == 0) {
          _this.finishedAwait = true
          // 无数据
        } else {
          _this.assessList = res.data
          _this.loadingAwait = false
          _this.finishedAwait = true
        }
      })
    },
    onLoadAwait() {
      if (this.khtime.autid == 0) {
        this._getKhTimeList()
        return
      }
      if (this.isLoadingAwait) {
        this.assessList = []
        this.isLoadingAwait = false
      }
      if (this.activeName == '360Tag') {
        this.getMy360KhListData()
      } else if (this.activeName == 'KPITag') {
        this.getMyKpiKhListData()
      } else if (this.activeName == 'GSTag') {
        this.getMyGsKhListData()
      }
    },
    onRefreshAwait() {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      // Toast('刷新成功')
    },
    // tab切换事件
    changeActive(name, title) {
      this.onRefreshAwait()
    },
    // 发起指标申请
    goExamine() {
      let title = this.$t('jxMyAssTargetDetails.txt16')
      let is360 = true
      const mautoid = 0
      const checkflag = 1
      if (this.activeName == '360Tag') {
        title = this.$t('jxMyAssTargetDetails.txt16')
        is360 = true
      } else {
        title = this.$t('jxMyAssTargetDetails.txt17')
        is360 = false
      }
      this.$router.push({
        path: '/jxMyAssTargetApply',
        query: {
          title: title,
          is360: is360,
          mautoid: mautoid,
          checkflag: checkflag
        }
      })
    },
    goToDetails(item) {
      const detaId = item.autoid
      if (this.activeName == 'GSTag') {
        this.$router.push({
          path: '/myGsDetails',
          query: {
            title: item.taskname,
            autoid: item.autoid,
            khtimeid: this.khtime.autoid
          }
        })
      } else {
        this.$router.push({
          path: '/jxApprovalTagDetails',
          query: {
            parentTitle: this.title,
            is360: this.activeName == '360Tag',
            mautoid: this.mautoid,
            detaId: detaId,
            lastscore: this.lastscore,
            readonly: this.readonly,
            khtimeid: this.khtime.autoid,
            is_zjp: this.is_zjp, // 是否显示直接评价人
            is_jjp: this.is_jjp, // 是否显示间接评价人
            from: 'jxMyAssTarget'
          }
        })
      }
    },
    // 删除按钮
    deleteTag(item) {
      console.log(item, 'itemitem')
      if (this.activeName == '360Tag') {
        // 删除360
        Dialog.confirm({
          title: this.$t('module.del'),
          message: this.$t('module.sureToDelete')
        })
          .then(() => {
            deleteMy360Indicator({ autoid: item.autoid }).then(res => {
              if (res.iserror == 0) {
                Toast.success(this.$t('module.DelSuc'))
                this.onRefreshAwait()
              }
            })
          })
          .catch(() => {})
      } else {
        // 删除KPI
        Dialog.confirm({
          title: this.$t('module.del'),
          message: this.$t('module.sureToDelete')
        })
          .then(() => {
            deleteMyKpiIndicator({ autoid: item.autoid }).then(res => {
              if (res.iserror == 0) {
                Toast.success(this.$t('module.DelSuc'))
                this.onRefreshAwait()
              }
            })
          })
          .catch(() => {})
      }
    },
    // 复制上周期的指标
    copy() {
      copyLastTimeIndicator({
        flag:
          this.activeName == '360Tag'
            ? 1
            : this.activeName == 'KPITag'
            ? 2
            : this.activeName == 'GSTag'
            ? 3
            : 0,
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        if (res.iserror == 0) {
          Toast.success('复制成功')
          this.onRefreshAwait()
        }
      })
    },
    // 催办
    cb() {
      urgeMyIndicator({
        flag:
          this.activeName == '360Tag'
            ? 1
            : this.activeName == 'KPITag'
            ? 2
            : this.activeName == 'GSTag'
            ? 3
            : 0,
        empid: this.userInfo.empid,
        khtimeid: this.khtime.autoid
      }).then(res => {
        if (res.iserror == 0) {
          Toast.success('催办成功')
          // this.onRefreshAwait()
        }
      })
    },
    // 添加按钮
    addItem() {
      // console.log(this.title, 'this.title')
      const length = this.assessList.length || 0
      let title = this.$t('jxMyAssTargetDetails.txt16'),
        is360 = true,
        item = {}
      if (this.activeName == '360Tag') {
        title = this.$t('jxMyAssTargetDetails.txt16')
        is360 = true
        item = this.Base360
      } else {
        title = this.$t('jxMyAssTargetDetails.txt17')
        is360 = false
        item = this.BaseKPI
      }

      this.$router.push({
        path: '/jxMyAssTargetDetails',
        query: {
          parentTitle: title,
          is360: is360,
          mautoid: item.autoid,
          checkflag: item.checkflag,
          detaId: '',
          length: length,
          usejj_forid: item.usejj_forid,
          usezj_forid: item.usezj_forid,
          isadd: 1,
          lastscore: this.lastscore,
          req_indicator_select: item.req_indicator_select,
          khtimeid: this.khtime.autoid
        }
      })
      // this.$router.push('/jxMyAssTargetDetails')
    },
    // 提交按钮
    subbitItem() {
      if (this.activeName == '360Tag') {
        // 提交360
        if (this.assessList.length > 0) {
          Dialog.confirm({
            title: this.$t('module.submit'),
            message: this.$t('module.areUsureUwantTosubmit')
          })
            .then(() => {
              postMy360PlanIndicator({
                mautoid: this.Base360.autoid,
                username: this.userInfo.username
              }).then(res => {
                if (res.iserror == 0) {
                  Toast.success(this.$t('module.submitScu'))
                  this.onRefreshAwait()
                  // this.onClickLeft()
                }
              })
            })
            .catch(() => {})
        } else {
          Toast.fail(this.$t('module.PleaseAddRecBeforeSub'))
        }
      } else {
        // 提交KPI
        if (this.assessList.length > 0) {
          Dialog.confirm({
            title: this.$t('module.submit'),
            message: this.$t('module.areUsureUwantTosubmit')
          })
            .then(() => {
              postMyKpiPlanIndicator({
                mautoid: this.BaseKPI.autoid,
                username: this.userInfo.username
              }).then(res => {
                if (res.iserror == 0) {
                  if (res.data[0].info) {
                    Toast.fail(res.data[0].info)
                  } else {
                    Toast.success(this.$t('module.submitScu'))
                    // this.onClickLeft()
                  }
                  this.onRefreshAwait()
                }
              })
            })
            .catch(() => {})
        } else {
          Toast.fail(this.$t('module.PleaseAddRecBeforeSub'))
        }
      }
    },
    // GS-我要确认
    confirm(item) {
      checkMyTask({
        autoid: item.autoid,
        empid: this.userInfo.empid
      }).then(res => {
        if (res.data[0].info) {
          Toast.fail(res.data[0].info)
        } else {
          Toast.success('确认成功！')
          this.onLoadAwait()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.myAssTag {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
      .van-nav-bar__text {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/ .van-nav-bar__right {
      color: #fff;
      .van-icon {
        color: #fff;
      }
      .van-nav-bar__text {
        color: #fff;
      }
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 120px);
      ::-webkit-scrollbar {
        display: none;
      }
    }

    .zb_group:first-of-type {
      margin-top: 0;
    }
    .zb_group {
      // display: flex;
      border-left: 6px solid #2b8df0;
      margin: 30px 0;
      .van-cell-group {
        .top_cell {
          .van-cell__title {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2b8df0;
          }
          .van-cell__value {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
        .btm_cell {
          .van-cell__title {
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .custom-title {
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
          }
          .van-cell__label {
            text-align: right;
            font-size: 28px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            .custom-score {
              font-size: 48px;
              font-family: DIN;
              font-weight: bold;
              color: #2b8df0;
            }
          }
        }
      }
      .ass_cell {
        // margin: 10px 0;
        margin-bottom: 10px;
        .van-cell__title {
          flex: 3;
          line-height: 54px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #2b8df0;
        }
        .van-cell__label {
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
        }
        .van-cell__value {
          position: relative;
          color: #333333;
          padding-bottom: 60px;
          .task_ischeck {
            position: absolute;
            bottom: 0;
            right: 0;
          }
          .custom-score {
            font-size: 48px;
            font-family: DIN;
            font-weight: bold;
            color: #2b8df0;
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      flex: 1;
      border-radius: 12px;
      margin: 0 5px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>
